<div
  [class]="{
    container: true,
    modal___blur: true,
    modal___noBottomGap: showingConsent,
    container___extraMargin: showingConsent,
  }"
  [@fadeAnimation]
>
  <div class="registrationBox">
    <app-auth
      [mode]="mode"
      (submitResponse)="confirm($event)"
      [centeredHeading]="true"
      [showBackArrow]="true"
      (showingConsent)="handleShowingConsentChanged($event)"
    />
  </div>
  <app-recaptcha-disclaimer class="recaptchaDisclaimer" />
</div>
