import { transition, trigger, useAnimation } from '@angular/animations'
import { Component, inject, OnDestroy, OnInit } from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'
import { BackendResponseFM } from '@naturalcycles/shared'
import { Decorate } from '@src/app/core/decorators/decorators'
import { GTMService } from '@src/app/core/services/analytics/gtm.service'
import { MixpanelService } from '@src/app/core/services/analytics/mixpanel.service'
import { CartService } from '@src/app/core/services/cart.service'
import { NavService } from '@src/app/core/services/nav.service'
import { getState, SignalStore } from '@src/app/core/store/signalStore'
import { fadeAnimation, staggerAnimation } from '@src/app/core/util/animations.util'
import { GTMEvent } from '@src/app/shared/typings/analytics'
import { AuthMode } from '@src/app/shared/typings/enum/auth'
import { ErrorHandlerType } from '@src/app/shared/typings/enum/error-handler'
import { Page } from '@src/app/shared/typings/enum/pages'
import { LoaderType } from '@src/app/shared/typings/ui'
import { Subscription } from 'rxjs'

@Component({
  selector: 'app-credentials-modal',
  templateUrl: './credentials-modal.component.html',
  styleUrls: ['./credentials-modal.component.scss'],
  animations: [
    trigger('fadeAnimation', [transition('* => *', [useAnimation(fadeAnimation)])]),
    trigger('staggerAnimation', [transition('* => *', [useAnimation(staggerAnimation)])]),
  ],
})
export class CredentialsModalComponent implements OnInit, OnDestroy {
  private store = inject(SignalStore)
  private gtmService = inject(GTMService)
  private mixpanelService = inject(MixpanelService)
  private cartService = inject(CartService)
  private navService = inject(NavService)
  private router = inject(Router)
  private subscription?: Subscription

  api!: { done: () => void }

  protected mode = AuthMode.signup
  protected Page = Page
  protected isOuraFlow?: boolean

  protected discountCode = this.store.$discountCode

  protected showingConsent = false

  ngOnInit(): void {
    // close modal on navigating back (we can't move forwards in the flow)
    this.subscription = this.router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        this.api.done()
      }
    })
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe()
  }

  @Decorate({
    loaderType: LoaderType.BLOCKING,
    errorHandlerType: ErrorHandlerType.DIALOG,
  })
  protected async confirm(resp: BackendResponseFM): Promise<void> {
    const { account } = getState()
    this.gtmService.push({
      accountId: account.externalPersonalId,
    })

    if (!resp.res?.isRegistered) {
      this.mixpanelService.register(account.externalAccountId)

      void this.gtmService.event({
        category: GTMEvent.RegistrationStart,
        action: this.cartService.getSubscriptionType()!,
      })
    } else {
      this.mixpanelService.identify(account.externalAccountId)
    }

    const discountCode = this.store.$discountCode()
    if (!!account.currentSubscriptionId && !discountCode) {
      // Todo maybe magic route for flow compliance
      return await this.navService.goToWebApp()
    }
    this.api.done()
  }

  protected handleShowingConsentChanged(event: boolean): void {
    this.showingConsent = event
  }
}
